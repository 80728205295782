import {
  Dialog,
  LayerHost,
  Link,
  Spinner,
  SpinnerSize,
  Stack,
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { makeStyles, shorthands } from "@griffel/react";
import { AndroidKeyCode, AndroidKeyEventAction } from "@yume-chan/scrcpy";
import { observer } from "mobx-react-lite";
import { NextPage } from "next";
import { KeyboardEvent, useEffect, useState } from "react";
import { DeviceView } from "../components";
import { STATE, VideoContainer } from "../components/scrcpy";
import { useLocalStorage } from "../hooks";
import { GLOBAL_STATE } from "../state";
import { RouteStackProps } from "../utils";

const getCookie = (cookieKey: string) => {
  const cookies = document.cookie
    .split(";")
    .filter((cookie) => cookie.includes(cookieKey));
  if (cookies.length === 0) {
    return null;
  }
  const values = cookies.map((cookie) => cookie.split("=")[1]);
  return values.join("");
};

const useClasses = makeStyles({
  layerHost: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    pointerEvents: "none",
    ...shorthands.margin(0),
  },
  fullScreenContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "black",
    ":focus-visible": {
      ...shorthands.outline("0"),
    },
  },
  fullScreenStatusBar: {
    display: "flex",
    color: "white",
    columnGap: "12px",
    ...shorthands.padding("8px", "20px"),
  },
  spacer: {
    flexGrow: 1,
  },
});

const ConnectingDialog = observer(() => {
  const classes = useClasses();
  const layerHostId = useId("layerHost");

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) {
    return null;
  }

  return (
    <>
      <LayerHost id={layerHostId} className={classes.layerHost} />

      <Dialog
        hidden={!STATE.connecting}
        modalProps={{ layerProps: { hostId: layerHostId } }}
        dialogContentProps={{ title: "Connecting..." }}
      >
        <Spinner
          label="Establishing Device Connection..."
          labelPosition={"right"}
          size={SpinnerSize.large}
        />
      </Dialog>
    </>
  );
});

async function handleKeyEvent(e: KeyboardEvent<HTMLDivElement>) {
  if (!STATE.client) {
    return;
  }

  e.preventDefault();
  e.stopPropagation();

  const { type, code } = e;
  STATE.keyboard![type === "keydown" ? "down" : "up"](code);
}

function handleBlur() {
  if (!STATE.client) {
    return;
  }

  STATE.keyboard?.reset();
}

const handleMessage = async (
  event: MessageEvent<{
    action: "HOME" | "VOLUME_UP" | "VOLUME_DOWN";
    type: "ACTION" | "SYNC_CONTACTS" | "SET_COMPANION_APP_CONFIG";
  }>
) => {
  const appURL = process.env.NEXT_PUBLIC_WEB_APP_URL;
  if (event.origin !== appURL) {
    console.log(
      `Received message from unknown origin: ${event.origin}. Expecting from ${appURL}`
    );
    return;
  }

  const {
    data: { type, action },
  } = event;

  if (type === "ACTION") {
    STATE.fullScreenContainer!.focus();

    STATE.client!.controlMessageWriter!.injectKeyCode({
      action: AndroidKeyEventAction.Down,
      keyCode: AndroidKeyCode[action as keyof typeof AndroidKeyCode],
      repeat: 0,
      metaState: 0,
    });
    STATE.client!.controlMessageWriter!.injectKeyCode({
      action: AndroidKeyEventAction.Up,
      keyCode: AndroidKeyCode[action as keyof typeof AndroidKeyCode],
      repeat: 0,
      metaState: 0,
    });
  } else if (type === "SYNC_CONTACTS") {
    console.log("Contact Sync");
    /*
      adb -s 29241FDH200ASL shell am broadcast -a com.nimbus.nimbusassist.action.ADB_COMMAND --es message "'start sync serial 29241FDH200ASL'"
    */
    sendCustomMessage("CONTACT_SYNC");
  } else if (type === "SET_COMPANION_APP_CONFIG") {
    console.log("Setting config for companion app");
    sendCustomMessage("SET_COMPANION_APP_CONFIG");
  }
};

const sendCustomMessage = (command: string) => {
  const deviceSerial = window.location.hash.replace("#", "");
  if (!deviceSerial) {
    console.log("No device serial found");
    return;
  }

  const cookieKey = process.env.NEXT_PUBLIC_WEB_APP_AUTH_COOKIE_KEY ?? "fail";
  const authCookie = getCookie(cookieKey);

  STATE.customMessageChannel?.send(
    `${command},${deviceSerial},${cookieKey}=${authCookie}`
  );
};

const FullscreenHint = observer(function FullscreenHint({
  keyboardLockEnabled,
}: {
  keyboardLockEnabled: boolean;
}) {
  const classes = useClasses();

  const [hintHidden, setHintHidden] = useLocalStorage<`${boolean}`>(
    "scrcpy-hint-hidden",
    "false"
  );

  if (!keyboardLockEnabled || !STATE.isFullScreen || hintHidden === "true") {
    return null;
  }

  return (
    <div className={classes.fullScreenStatusBar}>
      <div>{GLOBAL_STATE.device?.serial}</div>
      <div>FPS: {STATE.fps}</div>

      <div className={classes.spacer} />

      <div>Press and hold ESC to exit full screen</div>

      <Link onClick={() => setHintHidden("true")}>{`Don't show again`}</Link>
    </div>
  );
});

const Scrcpy: NextPage = () => {
  const classes = useClasses();

  useEffect(() => {
    if (!("keyboard" in navigator)) {
      return;
    }

    // Keyboard Lock is only effective in fullscreen mode,
    // but the `lock` method can be called at any time.

    // navigator.keyboard.lock();

    return () => {
      // navigator.keyboard.unlock();
    };
  }, []);

  useEffect(() => {
    window.addEventListener("blur", handleBlur);
    void STATE.start();
    STATE.navigationBarVisible = false;
    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("blur", handleBlur);
      window.removeEventListener("message", handleMessage);
      STATE.stop();
    };
  }, []);

  return (
    <Stack {...RouteStackProps}>
      {STATE.running ? (
        <Stack
          horizontal
          grow
          styles={{
            root: {
              height: 0,
              userSelect: "none",
              WebkitUserSelect: "none",
              MozUserSelect: "none",
              msUserSelect: "none",
            },
          }}
        >
          <div
            ref={STATE.setFullScreenContainer}
            className={classes.fullScreenContainer}
            tabIndex={0}
            onKeyDown={handleKeyEvent}
            onKeyUp={handleKeyEvent}
          >
            <DeviceView width={STATE.rotatedWidth} height={STATE.rotatedHeight}>
              <VideoContainer />
            </DeviceView>
          </div>
        </Stack>
      ) : (
        <ConnectingDialog />
      )}
    </Stack>
  );
};

export default observer(Scrcpy);
